.buchhaltung {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
}

.buchhaltung .header {
  width: 100%;
  background-color: var(--sennrüti-red);
}

.buchhaltung .vorausfüllen {
  margin-left: 4rem;
}

.buchhaltung .einträge {
  padding-top: 2rem;
  padding-bottom: 2rem;
  overflow-y: scroll;
  width: 100%;
}

.buchhaltung .line-edit {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buchhaltung span {
  font-size: 12px;
  text-align: right;
  width: 6rem;
  padding-right: .5rem;
}

.buchhaltung .kilometerstand {
  font-weight: bold;
}

.buchhaltung input {
  outline: 0;
  border-width: 0 0 2px;
  width: 8rem;
}

.buchhaltung input:focus {
  border-color: var(--sennrüti-orange);
  border-style: solid;
}

.buchhaltung input:invalid:not(:placeholder-shown) {
  border-color: var(--sennrüti-red);
  background-color: var(--sennrüti-red-light);
  border-style: solid;
}

.buchhaltung .add-button-wrapper {
  margin: -1.5rem 0 -1.5rem 0;
  z-index: 1;
}

@media screen and (max-width: 64rem) {
  .buchhaltung .add-button-wrapper {
    margin: 0;
    z-index: 1;
  }
}

.no-wrap {
  display: flex;
  align-items: center;
}

.buchhaltung button.add-button,
.buchhaltung button.delete-button {
  width: 2rem;
  height: 2rem;
  padding: 0;
  background-color: inherit;
  font-size: 18px;
  z-index: 1;
}

.einträge > .selection-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #88888888;
  z-index: 2;
}

.buchhaltung .selection-box {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  width: 6rem;
  bottom: 0;
  margin-left: 2rem;
  z-index: 2;
  background-color: white;
}

.buchhaltung .selection-box .item span {
  text-align: center;
}
