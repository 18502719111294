.hidden {
  display: none;
}

.vertical-space {
  margin-bottom: 16px;
}

.vertical-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.horizontal-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.message-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: var(--sennrüti-red-light);
  padding: 8px;
}

.carsharing-input {
  padding: .5rem;
  outline: 0 none;
  height: 1rem;
}

.carsharing-input:focus {
  border-color: var(--sennrüti-orange);
  border-style: solid;
}

.carsharing-input:invalid:not(:placeholder-shown) {
  border-color: var(--sennrüti-red);
  background-color: var(--sennrüti-red-light);
  border-style: solid;
}

