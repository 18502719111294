.abrechnung {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  margin-bottom: 2rem;
}

.abrechnung .name {
  width: 14rem;
}

.abrechnung .gelöschte-abrechnungen {
  margin-bottom: 2rem;
}

.abrechnung .edit-line-form {
  display: flex;
  align-items: center;
  flex-flow: row;
  flex-wrap: wrap;
}

.abrechnung .kostenstellen-title {
  font-weight: bold;
  border-bottom: 1px solid var(--carsharing-border-gray);
}

.abrechnung .edit-line-key {
  width: 16rem;
}

.abrechnung .edit-line-value {
  width: 15rem;
}

.abrechnung .edit-line-value textarea {
  width: 14rem;
}

.abrechnung .edit-line-controls {
  width: 12rem;
}

.abrechnung .overlay {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
}

.abrechnung .header {
  width: 100%;
  background-color: var(--sennrüti-red);
}

.abrechnung .content {
  overflow-y: scroll;
  width: 100%;
  margin: .5rem;
}
