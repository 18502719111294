.fuhrpark {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.fuhrpark h2 {
  text-align: center
}

.fuhrpark form {
  display: flex;
  align-items: center;
  flex-flow: row;
}

.fuhrpark .show-line-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}

.fuhrpark .show-line {
  margin-bottom: .5rem;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
}

.fuhrpark .show-line.empty {
  margin-bottom: 0;
}

.fuhrpark .show-line-key {
  align-self: flex-start;
  font-size: 14px;
  color: var(--sennrüti-red);
  width: 10rem;
  margin-left: .5rem;
}

.fuhrpark .show-line-value {
  width: 12rem;
  margin-left: .5rem;
  display: flex;
  flex-direction: column;
}

.fuhrpark .edit-line-form {
  display: flex;
  align-items: center;
  flex-flow: row;
  flex-wrap: wrap;
}

.fuhrpark .edit-line-key {
  width: 16rem;
}

.fuhrpark .edit-line-value {
  width: 15rem;
}

.fuhrpark .edit-line-value textarea {
  width: 14rem;
}

.fuhrpark .edit-line-controls {
  width: 12rem;
}

.kosten {
  display: flex;
  flex-direction: column;
}

.kosten form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.kosten .ausgabe {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid var(--carsharing-border-gray);
  margin: 0.25rem;
  padding: 0 1rem 0 1rem;
}

.kosten .ausgabe div {
  padding: 0 1rem 0 1rem;
}

.kosten .ausgabe .name {
  width: 10rem;
}

.kosten .ausgabe .preis {
  width: 8rem;
}

.kosten .ausgabe .datum {
  width: 8rem;
}

.kosten .ausgabe .username {
  width: 8rem;
}

.fuhrpark .fahrzeug-kosten {
  margin: 1rem 0 1rem 0;
}

.fuhrpark .neues-fahrzeug {
  margin: 1rem 0 1rem 0;
}

.fuhrpark .gelöschte-fahrzeuge {
  margin: 0 0 1rem 0;
}
