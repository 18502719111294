.title {
  background: var(--sennrüti-red);
  color: white;
  font-size: 14px;
  width: 100%;
  padding: 4px 0px 4px 0px;
}

.Buchen {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  font-size: 14px;
}

.Buchen > div {
  margin: 0 1rem 0 1rem;
  align-items: flex-start;
}

.Buchen .fahrzeug-auswahl {
  margin-top: 1rem;
}

.buchung-zusammenfassung {
  font-size: 12px;
  border: 1px solid var(--carsharing-border-gray);
  width: 17rem;
}

.buchung-zusammenfassung .info {
  background-color: #55B8CC;
  padding: 8px;
  min-height: 1.75rem;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.buchung-zusammenfassung th {
  text-align: right;
  width: 7rem;
}

.buchung-zusammenfassung td {
  text-align: left;
}

.selection-box {
  border: 1px solid var(--carsharing-border-gray);
}

.selection-box .item {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-content: stretch;
}

.selection-box span {
  font-size: 12px;
  padding: 0.5rem;
}

.selection-box .verfügbarkeit.gebucht {
  background: var(--sennrüti-red);
}

.selection-box .verfügbarkeit {
  width: 0.5rem;
}

.selection-box .selected-item {
  background-color: var(--sennrüti-orange);
}

.date-selection {
  margin-top: -1.5rem;
  padding-left: 4rem;
}

.date-selection-arrows {
  margin: 0 0.25rem 0 0.25rem;
  user-select: none;
}

.arrow-button {
  cursor: pointer;
  padding: 0.5rem;
  color: var(--carsharing-border-gray);
  font-size: 20px;
  font-weight: bold;
}

.arrow-button:hover {
  color: var(--sennrüti-orange);
}

