:root {
  --sennrüti-red: #A41322;
  --sennrüti-red-light: #FEEEDE;
  --sennrüti-orange: #FF9633;
  --carsharing-green-light: #DEFFDE;
  --carsharing-border-gray: #CCCCCC;
  --carsharing-button-gray: #E8E8E8;
}

@font-face {
    font-family: "Sakkal Majalla";
    src: url("font/majalla.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: Verdana, sans-serif;
  /*font-family: Alegreya Sans, sans-serif;*/
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--sennrüti-red);
  font-family: Sakkal Majalla, sans-serif;
  margin-bottom: 0px;
}

button {
  background: var(--carsharing-button-gray);
  color: #AAAAAA;
  border: none;
  padding: .5rem;
  margin: .25rem;
  cursor: pointer;
}

button:hover {
  color: var(--sennrüti-orange);
}

button.highlighted {
  background: var(--sennrüti-orange);
  color: white;
  border: none;
  padding: 8px;
  margin: 4px;
  cursor: pointer;
}

button.highlighted:hover {
  color: white;
}

#root {
  height: 100vh; /* make children scrollable */
  display: flex;
  flex-direction: column;
}

.Collapsible__trigger {
  display: inline-block;
  background: #E8E8E8;
  color: #AAAAAA;
  border: none;
  padding: .5rem 0 .5rem 0;
  cursor: pointer;
  font-size: 14px;
  margin: 0 auto;
  width: 100%;
}

.Collapsible__trigger.is-closed {
  width: auto;
  padding: .5rem;
}

.is-closed + .Collapsible__contentOuter {
  width: 0px;
}

.Collapsible__trigger {
  text-align: center;
}

.Collapsible__trigger:hover {
  color: var(--sennrüti-orange);
}

.Collapsible__trigger::after {
  content: '+';
  margin-left: 16px;
}

.Collapsible__trigger.is-open::after {
  content: '‒';
} 

.Collapsible__contentInner {
  border: 1px solid transparent; /* lib fix: make laxy collapsible extend on first click */
}
