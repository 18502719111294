.abgeschlossene-buchungen {
  margin: 20px;  
}

.buchungen {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buchung {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid var(--carsharing-border-gray);
  margin: 0.25rem;
  padding: 0 1rem 0 1rem;
}

.buchungs-fahrzeug-item {
  width: 10rem;
  text-align: left;
}

.buchungs-fahrer-item {
  width: 12rem;
  text-align: left;
}

.buchungs-datum-item {
  width: 12rem;
  text-align: left;
}

.buchungs-button-item {
  width: 5rem;
  text-align: left;
}
