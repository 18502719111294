.login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.login form {
  display: flex;
  flex-flow: column;
}

.login input {
  margin-bottom: .75rem;
}

.login input.wrong-input {
  border-color: var(--sennrüti-red);
  background: var(--sennrüti-red-light);
  border-style: solid;
}

.header-logo {
  height: 6rem;
}
