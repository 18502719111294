.time-range-picker {
  display: flex;
  flex-direction: row;
}

.uhrzeit {
  height: 2rem;
  margin-top: -1px;
  margin-right: 0.25rem;
  font-size: 14px;
  line-height: 0.25rem;
  text-indent: 0.5rem;
  float: left;
}

.time-range-picker .datum {
  display: flex;
  align-items: center;
  justify-content: center;
  writing-mode: vertical-rl;
  transform:rotate(180deg);
  font-size: 14px;
  height: 7rem;
}

.board-row {
  display: flex;
  flex-direction: column;
}

.squares {
  display: flex;
  flex-direction: column;
}

.square {
  background: var(--carsharing-green-light);
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 2rem;
  height: 2rem;
  width: 2rem;
  margin-right: -1px;
  margin-top: -1px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding: 0;
  text-align: center;
}

.selected {
  background: var(--sennrüti-orange);
}

.reserved {
  background: var(--sennrüti-red-light);
}

.selected.reserved {
  background: var(--sennrüti-red);
}

.select-start {
  background: #88DAEE;
}

.select-end {
  background: #55B8CC;
}

.disabled {
  background: #ddd;
}

@media screen and (max-height: 680px) {
  .uhrzeit {
    height: 1.5rem;
  }

  .square {
    line-height: 1.5rem;
    height: 1.5rem;
  }
}
