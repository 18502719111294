td,th {
  padding: 0 .5rem 0 .5rem;
}

.Carsharing {
  min-height: 0; /* make tab panel scrollable */
  display: flex;
  flex-direction: column;
}

.Tableiste {
  text-align: center;
}

.Tableiste > li:last-child {
  float: right;
}

.react-tabs {
  min-height: 0; /* make tab panel scrollable */
  display: flex;
  flex-direction: column;
}

.react-tabs .react-tabs__tab-panel--selected {
  min-height: 0;
  overflow-y: auto;
  overflow-anchor: none;
}

.react-tabs ul {
  background-color: var(--sennrüti-red);
  color: white;
  margin: 0 0 .25rem 0;
}

@media screen and (max-width: 340px) {
  .react-tabs__tab {
    padding: .5rem;
  }
}

.react-tabs__tab div {
  font-size: 12px;
}

.react-tabs__tab--selected,
.react-tabs__tab:hover {
  background-color: var(--sennrüti-orange) !important;
  border-color: var(--sennrüti-orange) !important;
  color: white !important;
  border-radius: 0 0 0 0 !important;
  bottom: 0 !important;
}

